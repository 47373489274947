import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px',
    borderRadius: 20,
    boxShadow: '2px 2px 10px 10px rgba(0,0,0,0.3)',
  },
  title: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    minWidth: 650,
    background: theme.custom.gradients.primary,
  },
  addForm: {
    width: '100%',
    maxWidth: 1200,
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputField: {
    marginBottom: '10px',
  },
  addButton: {
    marginTop: '10px',
    background: theme.custom.gradients.primary,
  },
}));

const ServicesPage = () => {
  const classes = useStyles();
  const [services, setServices] = useState([]);
  const [serviceName, setServiceName] = useState('');
  const [pricePer1000, setPricePer1000] = useState('');
  const [minOrder, setMinOrder] = useState('');
  const [maxOrder, setMaxOrder] = useState('');
  const [description, setDescription] = useState('');
  const [nextId, setNextId] = useState(1);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('/services');
        setServices(response.data);
        if (response.data.length > 0) {
          const maxId = Math.max(...response.data.map(service => service.id));
          setNextId(maxId + 1);
        }
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };
    fetchServices();
  }, []);

  const handleAddService = async () => {
    if (!serviceName || !pricePer1000 || !minOrder || !maxOrder || !description) {
      toast.warn('All fields are required');
      return;
    }

    const newService = {
      name: serviceName,
      pricePer1000,
      minOrder,
      maxOrder,
      description,
    };

    try {
      await axios.post('/services/add', newService);

      toast.success('Service added successfully');
      setServices([...services, newService]);
      setServiceName('');
      setPricePer1000('');
      setMinOrder('');
      setMaxOrder('');
      setDescription('');
      setNextId(nextId + 1);
    } catch (error) {
      toast.error('Error adding service');
    }
  };

  const handleRemoveService = async (id) => {
    try {
      await axios.delete(`/services/remove/${id}`);
      toast.success('Service removed successfully');
      setServices(services.filter(service => service.id !== id));
    } catch (error) {
      toast.error('Error removing service');
    }
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        Services
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Service</TableCell>
              <TableCell>Rate per 1000</TableCell>
              <TableCell>Min order</TableCell>
              <TableCell>Max order</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service) => (
              <TableRow key={service.id}>
                <TableCell>{service.id}</TableCell>
                <TableCell>{service.name}</TableCell>
                <TableCell>{service.pricePer1000}</TableCell>
                <TableCell>{service.minOrder}</TableCell>
                <TableCell>{service.maxOrder}</TableCell>
                <TableCell>{service.description}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRemoveService(service.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.addForm}>
        <Typography variant="h5" gutterBottom>
          Add a new service
        </Typography>
        <TextField
          className={classes.inputField}
          type="text"
          placeholder="Service Name"
          value={serviceName}
          onChange={(e) => setServiceName(e.target.value)}
          variant="outlined"
          fullWidth
          required
        />
        <TextField
          className={classes.inputField}
          type="text"
          placeholder="Price per 1000"
          value={pricePer1000}
          onChange={(e) => setPricePer1000(e.target.value)}
          variant="outlined"
          fullWidth
          required
        />
        <TextField
          className={classes.inputField}
          type="text"
          placeholder="Min Order"
          value={minOrder}
          onChange={(e) => setMinOrder(e.target.value)}
          variant="outlined"
          fullWidth
          required
        />
        <TextField
          className={classes.inputField}
          type="text"
          placeholder="Max Order"
          value={maxOrder}
          onChange={(e) => setMaxOrder(e.target.value)}
          variant="outlined"
          fullWidth
          required
        />
        <TextField
          className={classes.inputField}
          type="text"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          variant="outlined"
          fullWidth
          required
        />
        <Button
          className={classes.addButton}
          variant="contained"
          color="primary"
          onClick={handleAddService}
          fullWidth
        >
          Add Service
        </Button>
      </div>
      <ToastContainer />
    </Container>
  );
};

export default ServicesPage;
