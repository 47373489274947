import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  content: {
    marginBottom: theme.spacing(2),
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Box>
        <Typography variant="h4" className={classes.title}>
          Privacy Policy
        </Typography>
        <Typography variant="body1" className={classes.content}>
          Your privacy is important to us. This privacy policy explains what personal data we collect from you and how we use it.
        </Typography>
        <Typography variant="h6" className={classes.title}>
          Data Collection
        </Typography>
        <Typography variant="body1" className={classes.content}>
          We collect data to provide better services to all our users. The data we collect depends on the services you use and how you interact with them.
        </Typography>
        <Typography variant="h6" className={classes.title}>
          Data Usage
        </Typography>
        <Typography variant="body1" className={classes.content}>
          The data we collect is used to provide, maintain, protect, and improve our services, to develop new ones, and to protect our users.
        </Typography>
        <Typography variant="h6" className={classes.title}>
          Data Sharing
        </Typography>
        <Typography variant="body1" className={classes.content}>
          We do not share personal information with companies, organizations, or individuals outside of our company unless one of the following circumstances applies: with your consent, for legal reasons, or for external processing.
        </Typography>
        <Typography variant="h6" className={classes.title}>
          Your Choices
        </Typography>
        <Typography variant="body1" className={classes.content}>
          You have choices regarding the data we collect and how it's used. This includes the ability to opt-out of data collection and sharing under certain circumstances.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
