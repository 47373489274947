import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  content: {
    marginBottom: theme.spacing(2),
  },
}));

const TermsOfService = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Box>
        <Typography variant="h4" className={classes.title}>
          Terms of Service
        </Typography>
        <Typography variant="body1" className={classes.content}>
          These terms of service ("Terms") apply to your access and use of our services. By accessing or using our services, you agree to be bound by these Terms.
        </Typography>
        <Typography variant="h6" className={classes.title}>
          Using Our Services
        </Typography>
        <Typography variant="body1" className={classes.content}>
          You must follow any policies made available to you within the services. Do not misuse our services, and use them only as permitted by law.
        </Typography>
        <Typography variant="h6" className={classes.title}>
          Refund Policy
        </Typography>
        <Typography variant="body1" className={classes.content}>
          We offer a refund within 3 days of your purchase. If you are not satisfied with the service, you may request a refund within this period.
        </Typography>
        <Typography variant="h6" className={classes.title}>
          Modifying and Terminating Our Services
        </Typography>
        <Typography variant="body1" className={classes.content}>
          We are constantly changing and improving our services. We may add or remove functionalities or features, and we may suspend or stop a service altogether.
        </Typography>
        <Typography variant="h6" className={classes.title}>
          Liability for Our Services
        </Typography>
        <Typography variant="body1" className={classes.content}>
          When permitted by law, our company, suppliers, or distributors will not be responsible for lost profits, revenues, or data, financial losses, or indirect, special, consequential, exemplary, or punitive damages.
        </Typography>
        <Typography variant="h6" className={classes.title}>
          About These Terms
        </Typography>
        <Typography variant="body1" className={classes.content}>
          We may modify these terms or any additional terms that apply to a service to reflect changes to the law or changes to our services. You should look at the terms regularly.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;
