import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh',
    padding: '20px',
    background: theme.custom.gradients.primary,
  },
  form: {
    marginBottom: '20px',
  },
  select: {
    marginBottom: '20px',
  },
}));

const AddFundsPage = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [amount, setAmount] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleAddFunds = async () => {
    try {
      await axios.post('/users/add-funds', {
        userId: selectedUserId,
        amount: parseFloat(amount),
      });
      setAmount(''); // Clear the input field
      alert('Funds added successfully');
    } catch (error) {
      console.error('Error adding funds:', error);
    }
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h4" gutterBottom>
        Add Funds to User Account
      </Typography>
      <FormControl className={classes.select}>
        <InputLabel>User</InputLabel>
        <Select
          value={selectedUserId}
          onChange={(e) => setSelectedUserId(e.target.value)}
        >
          {users.map(user => (
            <MenuItem key={user.id} value={user.id}>
              {user.username} ({user.email})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Amount to Add"
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        className={classes.form}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddFunds}
      >
        Add Funds
      </Button>
      <TableContainer style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>${(user.balance || 0).toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AddFundsPage;
