import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container2: {
    background: theme.custom.gradients.primary,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh',
    padding: '20px',
    background: theme.custom.gradients.primary,
  },
  tableContainer: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60vh',
    background: theme.custom.gradients.primary,
  },
  table: {
    minWidth: 300,
    '@media (max-width: 600px)': {
      minWidth: 250,
    },
  },
  tableHead: {
    backgroundColor: 'lightgray',
  },
  tableHeadCell: {
    fontWeight: 'bold',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      // background: 'linear-gradient(to right, red , gray )',
    },
  },
}));
const HistoryPage = ({ userId }) => {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`/orders/${userId}`);
        const allOrders = response.data;
        setOrders(allOrders);  // Simply set the fetched orders
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
  
    fetchOrders();
  }, [userId]);

  return (
    <Container className={classes.container2}>
      <Container className={classes.container}>
        <Box>
          <Typography variant="h3" padding={5} display={'flex'} textAlign={'center'} gutterBottom>
            Order History
          </Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="order history table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell className={classes.tableHeadCell} sx={{ fontSize: '15px', fontWeight: 'bold' }}>Service Name</TableCell>
                  <TableCell className={classes.tableHeadCell} sx={{ fontSize: '15px', fontWeight: 'bold' }}>Link</TableCell>
                  <TableCell className={classes.tableHeadCell} sx={{ fontSize: '15px', fontWeight: 'bold' }}>Quantity</TableCell>
                  <TableCell className={classes.tableHeadCell} sx={{ fontSize: '15px', fontWeight: 'bold' }}>Total Amount</TableCell>
                  <TableCell className={classes.tableHeadCell} sx={{ fontSize: '15px', fontWeight: 'bold' }}>Date and Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell sx={{ fontSize: '14px' }}>{order.serviceName}</TableCell>
                    <TableCell sx={{ fontSize: '14px' }}>{order.link}</TableCell>
                    <TableCell sx={{ fontSize: '14px' }}>{order.quantity}</TableCell>
                    <TableCell sx={{ fontSize: '14px' }}>${order.totalAmount}</TableCell>
                    <TableCell sx={{ fontSize: '14px' }}>{new Date(order.date).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </Container>
  );
};

export default HistoryPage;

