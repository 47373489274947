import React from "react";
import { useState, useEffect } from "react";
import { Typography, Box, Link as MuiLink , Grid, Paper } from "@mui/material";
import { People, BusinessCenter, LocalOffer, Star } from '@mui/icons-material';
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import Particles from 'react-tsparticles';
import particlesConfig from './particlesConfig';
import axios from 'axios';
import youtubeImage from "./resources/youtube.png";
import tiktokImage from "./resources/tiktok.png";
import instagramImage from "./resources/instagram.png";
import websiteImage from "./resources/website.png";
import accountImage from "./resources/social.png";
import facebookImage from "./resources/facebook.png";

import us from "./resources/about.jpg";


const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(4),
    backgroundColor: "#fff",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    margin: theme.spacing(2, 0),
  },
  heading: {
    marginBottom: theme.spacing(2),
    color: "black",
    fontWeight: "bold",
    
  },
  mainSection: {
    position: 'relative',
    width: '100%',
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
background: theme.custom.gradients.secondary,
    overflow: 'hidden',
  },
  text: {
    zIndex: 1,
    color: '#fff',
    fontSize: '3rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  body: {
    "& h2": {
      fontSize: "1.8rem",
      color: "black",
      margin: theme.spacing(2, 0, 1),
    },
    "& h3": {
      fontSize: "1.7rem",
      color: "black",
      margin: theme.spacing(2, 0, 1),
    },
    "& p": {
      fontSize: "1rem",
      lineHeight: 1.6,
      margin: theme.spacing(1, 0),
    },
    "& ul": {
      paddingLeft: theme.spacing(2),
      margin: theme.spacing(1, 0),
      "& li": {
        margin: theme.spacing(0.5, 0),
      },
    },
  },
  serviceContainer: {
    display: 'flex',
    // height: '310px',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '20px',
  },
  serviceItem: {
    width: '250px',
    padding: '10px',
    height: '250px',
    perspective: '1000px',
    '&:hover $serviceInner': {
      transform: 'rotateY(180deg)',
    },
  },
  serviceInner: {
    width: '100%',
    
    height: '100%',
    position: 'relative',
    transition: 'transform 0.6s',
    transformStyle: 'preserve-3d',
    borderRadius: '10px',
    boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
  },
  serviceFront: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'orange',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    padding: '10px',
    textAlign: 'center',
    alignItems: 'end',
    justifyContent: 'center',
    borderRadius: '10px',
  },
  serviceBack: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    backgroundColor: theme.custom.gradients.primary,
    color: theme.palette.text.primary,
   background: theme.custom.gradients.primary,
    transform: 'rotateY(180deg)',
    borderRadius: '10px',
    padding: '20px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
serviceText: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    // height:'48px',
    // width:'290px',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    // marginTop: '15px',
    // padding: '10px',
    textAlign: 'center',
  },
  aboutSection: {
    padding: theme.spacing(6),
    backgroundImage: `url(${us})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#ffffff',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.2)', // Dark overlay for readability
      zIndex: 1,
    },
  },
  contentWrapper: {
    position: 'relative',
    zIndex: 2,
  },
  heading: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    fontSize: '2.5rem',
    fontWeight: 'bold',
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridItem: {
    textAlign: 'center',
  },
  paper: {
    background: theme.custom.gradients.secondary,
    // width:'250px',
    padding: theme.spacing(4),
    boxShadow: 'none',
  },
  iconContainer: {
    fontSize: '3rem !important', // Increase the icon size with !important
    marginBottom: theme.spacing(2),
    color: '#05fcfc',
  },
  textContent: {
    color: 'white',
    marginTop: theme.spacing(2),
  },
  footerSection: {
    backgroundColor: "#333",
    color: "#fff",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    margin: theme.spacing(2, 0),
    display: "flex",
    justifyContent: "space-between",
background: theme.custom.gradients.primary,
    alignItems: "flex-start",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  footerColumn: {
    flex: "1 1 30%",
    marginRight: theme.spacing(4),
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 0),
      textAlign: "center",
    },
  },
  footerHeading: {
    color: "#7de3de",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  footerText: {
    marginBottom: theme.spacing(2),
    lineHeight: "1.8",
  },
  footerLinks: {
    display: "flex",
    flexDirection: "column",
color: 'black',
    alignItems: "flex-start",
    "& > *": {
      marginBottom: theme.spacing(1),
     color: 'black',
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  
}));

const HomePage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [showServices, setShowServices] = useState(false);
  const [hoveredService, setHoveredService] = useState(false);
  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('/services');
        setServiceList(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };
    fetchServices();
  }, []);

 
  useEffect(() => {
    // Set a delay to show services with animation
    const timeout = setTimeout(() => {
      setShowServices(true);
    }, 500); // Adjust delay as needed

    return () => clearTimeout(timeout);
  }, []);

  const services = [
    { name: "YouTube Monetized Channel", bgImage: youtubeImage },
    { name: "UK USA Monetize TikTok Account", bgImage: tiktokImage },
    { name: "Instagram Followers", bgImage: instagramImage },
    { name: "Facebook Followers", bgImage: facebookImage },
    { name: "Website Traffic", bgImage: websiteImage },
    { name: "Social Media Account Management", bgImage: accountImage },
  ];

  const handleServiceClick = () => {
    navigate("/services-list");
  };

  return (
    <Box>
      {/* MAIN Section */}
      <Box id="main" className={classes.mainSection}>
        <Particles
          id="tsparticles"
          options={particlesConfig}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 'auto' }}
        />
        <Typography variant="h2" className={classes.text}>
          HARBINGER 360 SOLUTIONS
        </Typography>
      </Box>

        {/* SERVICES Section */}
        <Box id="services" className={classes.section}>
        <Typography variant="h3" className={classes.heading}>
          Our Services
        </Typography>
        <Box className={classes.serviceContainer}>
          {services.map((service, index) => (
            <Box
              key={index}
              className={classes.serviceItem}
              onMouseEnter={() => setHoveredService(true)}
              onMouseLeave={() => setHoveredService(false)}
              onClick={handleServiceClick}
            >
              <Box className={classes.serviceInner}>
                <Box
                  className={classes.serviceFront}
                  style={{ backgroundImage: `url(${service.bgImage})` }}
                >
                    <Box className={classes.serviceText}>{service.name}</Box>
                </Box>
                <Box className={classes.serviceBack}>
                  {hoveredService && serviceList.length > 0 ? (
                    <>
                      <Typography variant="h6"> Services</Typography>
                      <ul>
                        {serviceList.slice(0, 5).map((item, idx) => (
                          <li key={idx}>{item.name}</li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <Typography variant="body2">Loading...</Typography>
                  )}
                </Box>
              </Box>
            
              
            </Box>
          ))}
        </Box>
      </Box>


      <Box id="about-us" className={classes.aboutSection}>
      <div className={classes.contentWrapper}>
        <Typography variant="h3" className={classes.heading}>
          Get to know us
        </Typography>
        <Grid container spacing={3} className={classes.gridContainer}>
          {/* Harbinger Section */}
          <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
            <Paper className={classes.paper}>
              <People className={classes.iconContainer} />
              <Typography variant="h4">Harbinger</Typography>
              <Typography variant="body1" className={classes.textContent}>
              <p>Your one-stop destination for comprehensive social media services! At HSM Panel, we specialize in elevating your social media presence through expert account management, and a wide array of engagement services including followers, likes, views, and subscribers. Our mission is to empower individuals and businesses to harness the full potential of social media marketing.
              </p>
              </Typography>
            </Paper>
          </Grid>

          {/* Who We Are Section */}
          <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
            <Paper className={classes.paper}>
              <BusinessCenter className={classes.iconContainer} />
              <Typography variant="h4">Who We Are</Typography>
              <Typography variant="body1" className={classes.textContent}>
              <p>Harbinger is a team of passionate social media professionals dedicated to driving success for our clients. With years of experience in the dynamic world of social media, we understand the unique challenges and opportunities that come with building an online presence. Our goal is to provide  solutions that meet your needs, whether you're an influencer aiming to enhance your brand visibility.
              </p>
              </Typography>
            </Paper>
          </Grid>

          {/* What We Offer Section */}
          <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
            <Paper className={classes.paper}>
              <LocalOffer className={classes.iconContainer} />
              <Typography variant="h4">What We Offer</Typography>
              <Typography variant="body1" className={classes.textContent}>
              <p>Account Management: Let us handle the day-to-day management of your social media accounts. From content creation to audience engagement, we ensure your profiles stay active and appealing.
                Followers, Likes, Views, and Subscribers: Boost your social proof and attract more organic growth with our reliable and genuine engagement services. We offer customizable packages to suit your requirements.
                </p>
                </Typography>
            </Paper>
          </Grid>

          {/* Why Choose Us Section */}
          <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
            <Paper className={classes.paper}>
              <Star className={classes.iconContainer} />
              <Typography variant="h4">Why Choose Us</Typography>
              <Typography variant="body1" className={classes.textContent}>
             <p>Expertise: Our team consists of seasoned social media marketers who stay ahead of the latest trends and algorithms.</p>
             <p>Quality: We prioritize delivering high-quality services that produce real results. No bots, no fake accounts—just genuine engagement.</p>
             <p>Customization: Every client is unique, and so are our solutions. We tailor our services to match your specific goals and audience.</p>

              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Box>

      {/* Footer Section */}
      <Box id="contact-us" className={classes.footerSection}>
        {/*  Services Column */}
        <Box className={classes.footerColumn}>
          <Typography variant="h5" className={classes.footerHeading}>
            Harbinger
          </Typography>
          <Typography variant="body1" className={classes.footerText}>
            Harbinger, your one-stop destination for comprehensive social media
            services! At HSM Panel, we specialize in elevating your social media
            presence through expert account management, and a wide array of
            engagement services including followers, likes, views, and
            subscribers. Our mission is to empower individuals and businesses to
            harness the full potential of social media marketing.
          </Typography>
        </Box>

        {/* Our Products Column */}
        <Box className={classes.footerColumn}>
          <Typography variant="h6" className={classes.footerHeading}>
            Our Products
          </Typography>
          <Typography variant="body1" className={classes.footerText}>
            YouTube Services
            <br />
            TikTok accounts and services
            <br />
            Instagram Services
            <br />
            Website Traffic
            <br />
            Social Media Account Management
          </Typography>
        </Box>

        {/* Our Office Column */}
        <Box className={classes.footerColumn}>
          <Typography variant="h6" className={classes.footerHeading}>
            Contact Us
          </Typography>
          <Typography variant="body1" className={classes.footerText}>
            Blue Area Islamabad
          </Typography>
          <Typography variant="body1" className={classes.footerText}>
            Pakistan: +923149119908
          </Typography>
          <Typography variant="body1" className={classes.footerText}>
            Email: harbingermarketing.info@gmail.com
          </Typography>
          <Box className={classes.footerLinks}>
            <MuiLink href="/privacy-policy">Privacy Policy</MuiLink>
            <MuiLink href="/terms-of-service">Terms of Service</MuiLink>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
