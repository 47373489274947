import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Box, Typography, Select, MenuItem, TextField, Button, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddFundsModal from './AddFundsModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 170,
    alignItems: 'center',
    height: '60vh',
    padding: '20px',
    width: '400px',
    borderRadius: 5,
    background: theme.custom.gradients.primary,
  },
  formContainer: {
    maxWidth: '4500px',
    alignItems: 'center',
  },
  receiptContainer: {
    marginTop: '20px',
    padding: '10px',
    background: theme.custom.gradients.primary,
    borderRadius: '20',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  receiptText: {
    marginBottom: '10px',
  },
}));

const OrderPage =({ userId })  => {
  const classes = useStyles();
  const { serviceId } = useParams();
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(serviceId ? serviceId : '');
  const [link, setLink] = useState('');
  const [quantity, setQuantity] = useState('');
  const [charge, setCharge] = useState('');
  const [receipts, setReceipts] = useState([]);
  const [receiptVisible, setReceiptVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [minOrder, setMinOrder] = useState('');
  const [maxOrder, setMaxOrder] = useState('');
  const [description, setDescription] = useState('');
  const [balance, setBalance] = useState(0);


  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('/services');
        setServices(response.data);
      } catch (error) {
        toast.error('Error fetching services');
      }
    };
    fetchServices();
  }, []);

  useEffect(() => {
    const fetchBalance = async () => {
      if (userId) {
        try {
          const response = await axios.get(`/users/${userId}`);
          setBalance(response.data.balance || 0);
        } catch (error) {
          console.error('Error fetching balance:', error);
        }
      }
    };
    fetchBalance();
  }, [userId]);

 const handleOrder = async () => {
  try {
    const service = services.find(service => service.id === selectedService);
    if (!service) {
      toast.error('Service not found');
      return;
    }

    // Convert quantity to a number for comparison
    const quantityNumber = Number(quantity);

    if (isNaN(quantityNumber)) {
      toast.error('Quantity must be a number');
      return;
    }

    if (quantityNumber < service.minOrder || quantityNumber > service.maxOrder) {
      toast.error(`Order quantity must be between ${service.minOrder} and ${service.maxOrder}`);
      return;
    }

    if (parseFloat(charge) > balance) {
      toast.error('Insufficient balance');
      return;
    }

    // Deduct funds from the user's balance
    await axios.post('/users/deduct-funds', {
      userId,
      amount: parseFloat(charge),
    });

    // Place the order
    await axios.post('/orders/place', {
      userId,
      serviceId: selectedService,
      serviceName: service.name,
      quantity,
      link,
      totalAmount: parseFloat(charge), // Ensure totalAmount is a number
    });

    // Update balance and handle success
    setBalance(prevBalance => prevBalance - parseFloat(charge));
    setModalOpen(true); // Open the Add Funds modal
  } catch (error) {
    console.error('Error placing order:', error); // Log detailed error
    toast.error('Error placing order');
  }
};


  const handleServiceChange = (event) => {
    const { value } = event.target;
    const selectedService = services.find(service => service.id === value);

    if (selectedService) {
      setSelectedService(value);
      setMinOrder(selectedService.minOrder);
      setMaxOrder(selectedService.maxOrder);
      setDescription(selectedService.description);
      setReceiptVisible(false); // Hide receipts when changing service
    }
  };

  const handleQuantityChange = (event) => {
    const { value } = event.target;
    const service = services.find(service => service.id === selectedService);
    if (service) {
      setQuantity(value);
      const price = (service.pricePer1000 || 0) * (value / 1000);
      setCharge(price.toFixed(2));
    }
  };

  

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Container className={classes.container}>
      <ToastContainer />
      <Box className={classes.formContainer}>
        <Typography variant="h5" gutterBottom>
          Place an Order
        </Typography>
        <Select
          fullWidth
          value={selectedService}
          onChange={handleServiceChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Select Service' }}
        >
          {services.map(service => (
            <MenuItem key={service.id} value={service.id}>{service.name}</MenuItem>
          ))}
        </Select>
        {selectedService && (
          <>
            <Typography variant="body1" gutterBottom>
              Description: {description}
            </Typography>
            <TextField
              fullWidth
              label="Link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              margin="normal"
            />
            <TextField
              fullWidth
              type="number"
              label={`Quantity (Min: ${minOrder}, Max: ${maxOrder})`}
              value={quantity}
              onChange={handleQuantityChange}
              InputProps={{ inputProps: { min: minOrder, max: maxOrder } }}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Total Amount ($)"
              value={charge}
              InputProps={{ readOnly: true }}
              margin="normal"
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleOrder}
              style={{ marginTop: '20px' }}
            >
              Submit
            </Button>
          </>
        )}
      </Box>
      <Box>
        {receiptVisible && receipts.map(receipt => (
          <Box key={receipt.serviceId} className={classes.receiptContainer}>
            <Typography variant="h6" className={classes.receiptText}>
              Receipt for {receipt.name}
            </Typography>
            <Typography variant="body1" className={classes.receiptText}>
              Quantity: {receipt.quantity}
            </Typography>
            <Typography variant="body1" className={classes.receiptText}>
              Price: ${receipt.price.toFixed(2)}
            </Typography>
            <Typography variant="body1" className={classes.receiptText}>
              Price in PKR: {receipt.priceInPKR}
            </Typography>
          </Box>
        ))}
      </Box>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        className={classes.modal}
      >
        <AddFundsModal handleClose={handleCloseModal} />
      </Modal>
    </Container>
  );
};

export default OrderPage;
