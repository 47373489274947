// src/theme.js

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  shadows: [
    "none",
    "0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
    "0px 1px 5px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    "0px 1px 8px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
    "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)",
    // Add more shadows as needed
  ],
  custom: {
    gradients: {
      secondary: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(33,64,70,1) 40%, rgba(87,238,178,1) 50%, rgba(33,64,70,1) 60%, rgba(0,0,0,1) 100%);',
      primary: 'linear-gradient(25deg, rgba(80,98,98,1) 0%, rgba(94,188,203,1) 50%, rgba(80,98,98,1) 100%)',
      
    },
  },
});

export default theme;
