import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, TextField, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  },
  box: {
    flexDirection: 'column',
    maxWidth: 500,
    padding: '30px 50px',
    borderRadius: 10,
    boxShadow: '2px 2px 10px 10px rgba(0,0,0,0.3)',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60vh',
    padding: '20px',
    background: theme.custom.gradients.primary,
  },
  dataField: {
    marginBottom: '10px',
    width: '100%', // Ensure it takes the full width for better alignment
    textAlign: 'left', // Align text to the left
  },
  dataText: {
    marginBottom: '10px',
  },
  button: {
    backgroundColor: '#1976d2',
    color: 'white',
    '&:hover': {
      backgroundColor: '#115293',
    },
    marginTop: '20px',
  },
}));

const UserProfile = ({ userId }) => {
  const classes = useStyles();
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
  });
  const [newEmail, setNewEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`/users/${userId}`);
        setUserData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [userId]);

  const handleUpdateEmail = async () => {
    if (!newEmail) {
      toast.error('Email field is required.');
      return;
    }
    try {
      const response = await axios.put(`/users/${userId}/email`, {
        email: newEmail,
      });
      toast.success(response.data.message);
      setNewEmail('');
    } catch (error) {
      console.error('Error updating email:', error);
      toast.error(error.response.data.message);
    }
  };

  const handleUpdateUsername = async () => {
    if (!newUsername) {
      toast.error('Username field is required.');
      return;
    }
    try {
      const response = await axios.put(`/users/${userId}/username`, {
        username: newUsername,
      });
      toast.success(response.data.message);
      setNewUsername('');
    } catch (error) {
      console.error('Error updating username:', error);
      toast.error(error.response.data.message);
    }
  };

  const handleUpdatePassword = async () => {
    if (!newPassword) {
      toast.error('Password field is required.');
      return;
    }
    try {
      const response = await axios.put(`/users/${userId}/password`, {
        password: newPassword,
      });
      toast.success(response.data.message);
      setNewPassword('');
    } catch (error) {
      console.error('Error updating password:', error);
      toast.error(error.response.data.message);
    }
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box className={classes.container}>
      <ToastContainer />
      <Box className={classes.box}>
        <Typography variant="h5" component="h1" gutterBottom>
          Your Information
        </Typography>
        <Typography className={classes.dataText}>{`First Name: ${userData.firstName}`}</Typography>
        <Typography className={classes.dataText}>{`Last Name: ${userData.lastName}`}</Typography>
        <Typography className={classes.dataText}>{`Username: ${userData.username}`}</Typography>
        <Typography className={classes.dataText}>{`Email: ${userData.email}`}</Typography>

        <TextField
          label="New Email"
          variant="outlined"
          fullWidth
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          className={classes.dataField}
        />
        <Button onClick={handleUpdateEmail} className={classes.button}>
          Update Email
        </Button>

        <TextField
          label="New Username"
          variant="outlined"
          fullWidth
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
          className={classes.dataField}
        />
        <Button onClick={handleUpdateUsername} className={classes.button}>
          Update Username
        </Button>

        <TextField
          label="New Password"
          type="password"
          variant="outlined"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className={classes.dataField}
        />
        <Button onClick={handleUpdatePassword} className={classes.button}>
          Update Password
        </Button>
      </Box>
    </Box>
  );
};

export default UserProfile;
