import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    background: 'linear-gradient(to right, #d4cfc7, #d4cfc7)',
  },
  box: {
    maxWidth: '800px',
    marginTop: '20%',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '30vh',
    padding: '20px',
    borderRadius: 20,
    boxShadow: '2px 2px 10px 10px rgba(0,0,0,0.3)',
    background: theme.custom.gradients.primary,
  },
  button: {
    backgroundColor: '#1976d2',
    color: 'white',
    '&:hover': {
      backgroundColor: '#115293',
    },
  },
  signUpText: {
    marginTop: '10px',
    textAlign: 'center',
  },
  signUpLink: {
    textDecoration: 'none',
    color: '#1976d2',
    fontWeight: 'bold',
    marginLeft: '5px',
  },
}));

const SignIn = ({ onSignIn }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      const response = await axios.post('/auth/signIn', { email, password });

      if (response && response.data) {
        onSignIn(response.data.userId, email); // Pass userId and email to parent
        navigate('/');
      } else {
        alert('Empty response or missing data');
      }
    } catch (error) {
      console.error('Error during sign-in:', error);
      alert(error.response.data.message);
    }
  };

  return (
    <Container >
      <Box className={classes.box}>
        <Typography variant="h5" component="h1" gutterBottom>
          Sign In
        </Typography>
        <TextField
          fullWidth
          label="Email"
          type="email"
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          fullWidth
          variant="contained"
          className={classes.button}
          onClick={handleSignIn}
          style={{ marginTop: '20px' }}
        >
          Sign In
        </Button>
        <Typography className={classes.signUpText}>
          Don't have an account?
          <Link to="/signup" className={classes.signUpLink}>
            Sign Up
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default SignIn;
