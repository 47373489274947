import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import axios from 'axios';
import HomePage from './components/HomePage';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import OrderPage from './components/OrderPage';
import HistoryPage from './components/HistoryPage';
import ServicesPage from './components/ServicesPage';
import ServicesList from './components/ServicesList';
import AddFundsPage from './components/AddFundsPage';
import AddBalancePage from './components/AddBalancePage';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Profile from './components/Profile';
import logo from "./components/resources/logo2.png";
import AddFundsModal from './components/AddFundsModal';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#A9A9A9',
    width: '100%',
  },
  navLinks: {
    marginLeft: theme.spacing(2),
    color: 'white',
    textTransform: 'none',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'red',
    },
  },
  logo: {
    display: 'block',
    marginLeft: 'flex-start',
    marginRight: 'auto',
    width: '160px', // Adjust width as needed
    marginBottom: '5px',
  },
  drawerPaper: {
    width: 240,
  },
}));

const App = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:700px)');
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState('');
 const [balance, setBalance] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setIsSignedIn(true);
      setUserId(user.id);
      setUserEmail(user.email);
    }
  }, []);

 useEffect(() => {
    if (isSignedIn && userId) {
      const fetchBalance = async () => {
        try {
          const response = await axios.get(`/users/${userId}`);
          setBalance(response.data.balance || 0);
        } catch (error) {
          console.error('Error fetching balance:', error);
        }
      };
      fetchBalance();
    } else {
      setBalance(null);
    }
  }, [isSignedIn, userId]);

  const handleSignIn = (id, email) => {
    setIsSignedIn(true);
    setUserId(id);
    setUserEmail(email);
    localStorage.setItem('user', JSON.stringify({ id, email }));
    navigate('/');
  };

  const handleLogout = () => {
    setIsSignedIn(false);
    setUserId(null);
    setUserEmail('');
    localStorage.removeItem('user');
    navigate('/');
  };

  const handleNavigation = (section) => {
    navigate('/');
    setTimeout(() => {
      document.getElementById(section).scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const drawerContent = (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={() => handleNavigation('services')}>
          <ListItemText primary="Services" />
        </ListItem>
        <ListItem button onClick={() => handleNavigation('about-us')}>
          <ListItemText primary="About Us" />
        </ListItem>
        <ListItem button onClick={() => handleNavigation('contact-us')}>
          <ListItemText primary="Contact Us" />
        </ListItem>
        {isSignedIn ? (
          <>
            <ListItem button component={Link} to="/order">
              <ListItemText primary="Order" />
            </ListItem>
            <ListItem button component={Link} to="/profile">
              <ListItemText primary="Profile" />
            </ListItem>
            <ListItem button component={Link} to="/history">
                  <ListItemText primary="History" />
                </ListItem>
                <ListItem button component={Link} to="/addbalance">
                  <ListItemText primary="Add Balance" />
                </ListItem>
            {userEmail === 'asimsahar417@gmail.com' && (
              <>
                <ListItem button component={Link} to="/history">
                  <ListItemText primary="History" />
                </ListItem>
                <ListItem button component={Link} to="/services">
                  <ListItemText primary="Edit-Services" />
                </ListItem>
                <ListItem button component={Link} to="/add-funds">
                  <ListItemText primary="Add Funds" />
                </ListItem>
              </>
            )}
            <ListItem button onClick={handleLogout}>
              <ListItemText primary="Logout" />
            </ListItem>
          </>
        ) : (
          <ListItem button component={Link} to="/signIn">
            <ListItemText primary="Log In" />
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <Box>
      <AppBar position="fixed" color="inherit" className={classes.appBar}>
        <Toolbar>
          <img src={logo} alt="Logo" className={classes.logo} onClick={() => navigate('/')} />
          <Box className={classes.balanceContainer}>
      {balance !== null && (
        <Typography className={classes.balanceText}>
          Balance: ${balance}
        </Typography>
      )}
    </Box>
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                classes={{ paper: classes.drawerPaper }}
              >
                {drawerContent}
              </Drawer>
            </>
          ) : (
            <>
              <Button color="inherit" className={classes.navLinks} onClick={() => handleNavigation('services')}>
                Home
              </Button>
              <Button color="inherit" className={classes.navLinks} onClick={() => handleNavigation('about-us')}>
                About Us
              </Button>
              <Button color="inherit" className={classes.navLinks} onClick={() => handleNavigation('contact-us')}>
                Contact Us
              </Button>
              {isSignedIn ? (
                <>
                  <Button component={Link} to="/order" color="inherit" className={classes.navLinks}>
                    Order
                  </Button>
                  <Button component={Link} to="/profile" color="inherit" className={classes.navLinks}>
                    Profile
                  </Button>
                      <Button component={Link} to="/history" color="inherit" className={classes.navLinks}>
                        History
                      </Button>
                      <Button component={Link} to="/addbalance" color="inherit" className={classes.navLinks}>
                        Add Balance
                      </Button>
                  
                  {userEmail === 'asimsahar417@gmail.com' && (
                    <>
                      <Button component={Link} to="/services" color="inherit" className={classes.navLinks}>
                        Edit-Services
                      </Button>
                      <Button component={Link} to="/add-funds" color="inherit" className={classes.navLinks}>
                        Add Funds
                      </Button>
                      <Button component={Link} to="/addfunds" color="inherit" className={classes.navLinks}>
                        paymentMethod
                      </Button>

                    </>
                  )}
                  <Button color="inherit" className={classes.navLinks} onClick={handleLogout}>
                    Logout
                  </Button>
                </>
              ) : (
                <Button component={Link} to="/signIn" color="inherit" className={classes.navLinks}>
                  Log In
                </Button>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
      <Box style={{ marginTop: '64px' }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/signIn" element={<SignIn onSignIn={handleSignIn} />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/services-list" element={<ServicesList isAuthenticated={isSignedIn} />} />
          {isSignedIn && (
            <>
              <Route path="/order" element={<OrderPage userId={userId} />} />
              <Route path="/profile" element={<Profile userId={userId} />} />
              <Route path="/history" element={<HistoryPage userId={userId} />} />
                  <Route path="/addbalance" element={<AddBalancePage />} />
              {userEmail === 'asimsahar417@gmail.com' && (
                <>
                  <Route path="/services" element={<ServicesPage />} />
                  <Route path="/add-funds" element={<AddFundsPage />} />
                  <Route path="/addfunds" element={<AddFundsModal />} />
                  
                </>
              )}
            </>
          )}
        </Routes>
      </Box>
    </Box>
  );
};

export default App;
