import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';

const useStyles = makeStyles((theme) => ({
  container: {
    // Your container styles here
  },
  box: {
    maxWidth: '800px',
    marginTop: '20%',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '25vh',
    padding: '20px',
    borderRadius: 20,
    boxShadow: '2px 2px 10px 10px rgba(0,0,0,0.3)',
    background: theme.custom.gradients.primary,
  },
  button: {
    backgroundColor: '#1976d2',
    color: 'white',
    '&:hover': {
      backgroundColor: '#115293',
    },
  },
  signInText: {
    marginTop: '10px',
    textAlign: 'center',
  },
  signInLink: {
    textDecoration: 'none',
    color: '#1976d2',
    fontWeight: 'bold',
    marginLeft: '5px',
  },
}));

const SignUp = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async () => {
    // Validate email format
    if (!validator.isEmail(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    // Check password length
    if (password.length < 6) {
      toast.error('Password must be at least 6 characters long.');
      return;
    }

    if (!username) {
      toast.error('Username is required.');
      return;
    }

    if (!password) {
      toast.error('Please enter a password.');
      return;
    }

    if (password !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post('/auth/signup', {
        firstName,
        lastName,
        username,
        email,
        password,
      });
      toast.success(response.data.message);
      navigate('/signIn');
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred. Please try again.');
      }
    }
  };

  return (
    <Container className={classes.container}>
      <Box className={classes.box}>
        <Typography variant="h5" component="h1" gutterBottom>
          Sign Up
        </Typography>
        <TextField
          fullWidth
          label="First Name"
          margin="normal"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          fullWidth
          label="Last Name"
          margin="normal"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          fullWidth
          label="Username"
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Email"
          type="email"
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Confirm Password"
          type="password"
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          fullWidth
          variant="contained"
          className={classes.button}
          onClick={handleSignUp}
          style={{ marginTop: '20px' }}
        >
          Sign Up
        </Button>
        <Typography className={classes.signInText}>
          Already have an account?
          <Link to="/signIn" className={classes.signInLink}>
            Sign In
          </Link>
        </Typography>
      </Box>
      <ToastContainer />
    </Container>
  );
};

export default SignUp;
