import React, { useState } from 'react';
import { Container, Typography, TextField, Button, MenuItem, Select, FormControl, InputLabel, Box, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { yellow, blue, green, red } from '@mui/material/colors';

import MuiAlert from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh',
    padding: '20px',
    background: theme.custom.gradients.primary,
  },
  paymentMethod: {
    marginBottom: '20px',
  },
  copyable: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

const AddBalancePage = () => {
  const classes = useStyles();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleCopy = () => {
    setOpenSnackbar(true);
    setTimeout(() => setOpenSnackbar(false), 1000); // Hide snackbar after 2 seconds
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h4" gutterBottom>
        Add Balance to Your Account
      </Typography>

      <FormControl fullWidth className={classes.paymentMethod}>
        <InputLabel>Select Payment Method</InputLabel>
        <Select
          value={paymentMethod}
          onChange={handlePaymentMethodChange}
          label="Select Payment Method"
        >
          <MenuItem value="easypaisa">Easypaisa</MenuItem>
          <MenuItem value="jazzcash">Jazzcash</MenuItem>
          <MenuItem value="bank-transfer">Bank Transfer</MenuItem>
        </Select>
      </FormControl>

      {paymentMethod === 'easypaisa' && (
        <Box>
          <Typography variant="h6">Easypaisa</Typography>
          <Typography>Account Name: Muhammad Asim</Typography>
          <CopyToClipboard text="03149119908" onCopy={handleCopy}>
            <Typography className={classes.copyable}>Account Number: 03149119908</Typography>
          </CopyToClipboard>
        </Box>
      )}

      {paymentMethod === 'jazzcash' && (
        <Box>
          <Typography variant="h6">Jazzcash</Typography>
          <Typography>Account Name: Muhammad Asim</Typography>
          <CopyToClipboard text="03149119908" onCopy={handleCopy}>
            <Typography className={classes.copyable}>Account Number: 03149119908</Typography>
          </CopyToClipboard>
        </Box>
      )}

      {paymentMethod === 'bank-transfer' && (
        <Box>
          <Typography variant="h6">Bank Transfer</Typography>
          <Typography>Account Name: Muhammad Asim</Typography>
          
          <CopyToClipboard text="824591001482" onCopy={handleCopy}>
            <Typography className={classes.copyable}>Account Number: 824591001482</Typography>
          </CopyToClipboard>

          <CopyToClipboard text="PK85MUCB1185824591001482" onCopy={handleCopy}>
            <Typography className={classes.copyable}>IBAN: PK85MUCB1185824591001482</Typography>
          </CopyToClipboard>
        </Box>
      )}

      <Box mt={4}>
        <Typography variant="h6">Steps to Add Balance:</Typography>
        <Typography>1. Copy the account number or IBAN from above.</Typography>
        <Typography>2. Make the payment and take a screenshot. Send it to 03149119908.</Typography>
        <Typography>3. Wait for 2 to 3 hours, then check your account. The balance will be added.</Typography>
        <Typography>4. Don't panic if it's delayed. WhatsApp us again or call if not responded within 2 hours.</Typography>
      </Box>

      <Snackbar open={openSnackbar} autoHideDuration={2000}>
        <MuiAlert elevation={6} variant="filled" severity="success">
          Copied to clipboard!
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default AddBalancePage;
