import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    marginTop: '30px',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
    textAlign: 'center',
    margin: 'auto',
    marginTop: '10%',
    background: theme.custom.gradients.primary,
  },
}));

const AddFundsModal = ({ handleClose }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.modalContent}>
      <Typography variant="h5" gutterBottom>
        Your Order has been Placed Successfully
      </Typography>
      <Typography variant="h6" gutterBottom>
        Steps to Add Funds:
      </Typography>
      <Typography variant="body1">
        1. Take a screenshot of the order you have made and WhatsApp it to 03149119908.
      </Typography>
      <Typography variant="body1">
        Note: If you closed the screen, don’t worry. Just go to the history, take a screenshot, mark the order, and send it to us on WhatsApp.
      </Typography>
      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          
        >
        
          OK
        </Button>
      </Box>
    </Paper>
  );
};

export default AddFundsModal;
