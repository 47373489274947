import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    minHeight: '100vh',
    padding: '20px',
background: theme.custom.gradients.secondary,
  },
  tableContainer: {
    maxWidth: '1000px',
    marginTop: '30px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    background: theme.custom.gradients.primary,
  },
  tableHeader: {
    backgroundColor: '#A9A9A9',
  },
  tableHeaderCell: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  tableRow: {
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: '#f5f5f5',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#ffffff',
    },
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
}));

const ServicesList = ({ isAuthenticated }) => {
  const classes = useStyles();
  const [services, setServices] = useState([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('/services');
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };
    fetchServices();
  }, []);

  const handleServiceClick = () => {
    if (isAuthenticated) {
      navigate(`/order/`);
    } else {
      toast.error('Please sign in to order.');
    }
  };

  return (
    <Container className={classes.container}>
      <Box>
        <Typography variant="h3" padding={5} gutterBottom>
          Services
        </Typography>
        <Paper className={classes.tableContainer}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>Service Name</TableCell>
  
                <TableCell className={classes.tableHeaderCell}>Price per 1000</TableCell>
                <TableCell className={classes.tableHeaderCell}>Min Order</TableCell>
                <TableCell className={classes.tableHeaderCell}>Max Order</TableCell>
                <TableCell className={classes.tableHeaderCell}>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {services.map((service) => (
                <TableRow
                  key={service.id}
                  className={classes.tableRow}
                  onClick={() => handleServiceClick(service.id)}
                >
                  <TableCell>{service.name}</TableCell>
       
                  <TableCell>${service.pricePer1000}</TableCell>
                  <TableCell>{service.minOrder}</TableCell>
                  <TableCell>{service.maxOrder}</TableCell>
                  <TableCell>{service.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <ToastContainer />
      </Box>
    </Container>
  );
};

export default ServicesList;
